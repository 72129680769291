import { Outlet } from "react-router-dom";
import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import IdleTimer from "./IdleTimer";

import Masthead from "./Masthead";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  main: {
    paddingTop: 98,
    paddingBottom: 60,
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    alignContent: "center",
    alignItems: "center",
    flexGrow: 1,
    // background: `url(${backgroundImage})`,  // Could use the image but the image requires more css to match the Figma
    backgroundSize: "1.5em",
    backgroundImage: `linear-gradient(to bottom, white 30%, rgba(255,255,255,0.5) 45%, rgba(255,255,255,0.5) 100%, white 100%), Url(https://www.providencehealthplan.com/Providence/Images/Website/repeater-plus-slate.svg)`,
    backgroundRepeat: "repeat",
  },
  section: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Layout = ({ _isLoading }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <IdleTimer />
      <Masthead />
      <main className={classes.main}>
        <section className={classes.section}>
          <Outlet />
        </section>
      </main>
      <Footer />
      <Backdrop className={classes.backdrop} open={_isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
};

export default Layout;
