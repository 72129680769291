import { Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import {
  DateOfBirth,
  FirstName,
  GroupId,
  LastName,
  LookupType,
  MemberId,
  ZipCode,
} from "./Components";

import config from "../../../config";
import {
  LOOKUP_TYPE_BY_ID,
  LOOKUP_TYPE_BY_NAME,
} from "../../../utils/constants";

const useStyles = makeStyles((theme) => ({
  formTitle: {
    marginBottom: 10,
    textAlign: "center",
  },
  form: {
    fontFamily: "Inter",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    "& > div": {
      margin: [[theme.spacing(1), 0]],
      minWidth: "10rem",
      // maxWidth: '18rem',
      width: "90%",
      "@media only screen and (min-width: 500px)": {
        width: "80%",
      },
      "@media only screen and (min-width: 600px)": {
        width: "50%",
      },
    },
  },
  calendarIcon: {
    marginRight: 0,
  },
  linkText: {
    textAlign: "center",
    marginTop: 20,
    fontWeight: 600,
  },
  subheading: {
    fontFamily: "Inter",
    fontSize: "1.2rem",
    fontWeight: 1000,
    fontStyle: "normal",
    letterSpacing: 1,
    wordBreak: "break-word",
    marginTop: "1rem",
  },
}));

const VerificationForm = ({
  formData: { groupId, memberId, firstName, lastName, dateOfBirth, zipCode },
  inputVariant = "standard",
  handleFormChange,
  lookupType,
  onLookupTypeChange,
  forceUseMemberId,
  handleUseMemberIdAndReset,
  handleBlur
}) => {
  const classes = useStyles();

  const handleLookupTypeChange = (event) => {
    const newValue = event;
    onLookupTypeChange(newValue);
  };

  const handleUseMemberIdClick = (event) => {
    // event.preventDefault();
    handleUseMemberIdAndReset();
  };

  return (
    <>
      <form
        noValidate
        autoComplete="on"
        className={classes.form}
        id="form-register-relationship"
      >
        <DateOfBirth
          dateOfBirth={dateOfBirth}
          handleFormChange={handleFormChange}
        />

        <ZipCode
          inputVariant={inputVariant}
          zipCode={zipCode}
          handleFormChange={handleFormChange}
        />

        <Typography
          variant="h3"
          component="h2"
          align="center"
          className={classes.subheading}
          style={{ marginBottom: "1.5rem", marginTop: "1.5rem" }}
        >
          How are we identifying you today?
        </Typography>

        <LookupType
          lookupType={lookupType}
          forceUseMemberId={forceUseMemberId}
          handleLookupTypeChange={handleLookupTypeChange}
          handleUseMemberIDAndReset={handleUseMemberIdClick}
        />

        {forceUseMemberId ? (
          <Link
            href="#"
            onClick={() => handleUseMemberIdClick()}
            style={{
              marginBottom: "1.5rem",
              marginTop: "1rem",
              fontWeight: "bold",
            }}
          >
            Try signing up with your Member ID.
          </Link>
        ) : null}

        {lookupType === LOOKUP_TYPE_BY_NAME ? (
          <>
            <FirstName
              firstName={firstName}
              inputVariant={inputVariant}
              handleFormChange={handleFormChange}
              required={true}
              handleBlur={handleBlur}
            />
            <LastName
              lastName={lastName}
              inputVariant={inputVariant}
              handleFormChange={handleFormChange}
              required={true}
              handleBlur={handleBlur}
            />
          </>
        ) : null}

        {lookupType === LOOKUP_TYPE_BY_ID ? (
          <>
            <MemberId
              memberId={memberId}
              inputVariant={inputVariant}
              handleFormChange={handleFormChange}
              required={true}
            />
            <GroupId
              groupId={groupId}
              inputVariant={inputVariant}
              handleFormChange={handleFormChange}
              required={true}
            />
          </>
        ) : null}
      </form>

      <Typography
        variant="subtitle1"
        className={classes.linkText}
        id="register-relationship-link"
        gutterBottom
      >
        <Link
          target="_blank"
          rel="noopener"
          href={
            config?.faqUrl ||
            "https://myprovbeta-restyle.healthtrioconnect.com/public-app/content/member/payorPage.page?pptitle=registering%20assistance%20and%20faq"
          }
        >

            Need help registering today?

        </Link>
      </Typography>
    </>
  );
};

export default VerificationForm;
