import isPast from "date-fns/isPast";

//[a-zA-Z][a-zA-Z0-9-_]
const regularExpressions = {
  groupId: /[a-zA-Z0-9-_]{4,10}/,
  memberId: /[a-zA-Z0-9-_]{4,10}/,
  zipCode: /^\d{5,6}(?:[-\s]\d{4})?$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}/,
  firstName: /^(?!\s*$).+/,
  middleName: "",
  lastName: /^(?!\s*$).+/,
};

export const validateRegistrationForm = (field, value, extendedValidations) => {
  let isValid = false;
  let fieldValidation = {
    isValid: false,
    error: "",
  };

  switch (field) {
    case "dateOfBirth":
      isValid = isPast(new Date(value));
      fieldValidation = {
        isValid,
        error: isValid ? "" : "Cannot be future date",
      };
      break;
    case "groupId":
      isValid = regularExpressions[field].test(value);
      fieldValidation = {
        isValid,
        error: isValid ? "" : "Invalid Group ID format",
      };
      break;
    case "memberId":
      isValid = regularExpressions[field].test(value);
      fieldValidation = {
        isValid,
        error: isValid ? "" : "Invalid Member ID format",
      };
      break;
    case "zipCode":
      isValid = regularExpressions[field].test(value);
      fieldValidation = {
        isValid,
        error: isValid ? "" : "Invalid Zip Code",
      };
      break;
    case "email":
      isValid = regularExpressions[field].test(value);
      fieldValidation = {
        isValid,
        error: isValid ? "" : "Invalid Email",
      };
      break;
    case "password":
      isValid = regularExpressions[field].test(value);
      fieldValidation = {
        isValid,
        error: isValid ? "" : "Password Not strong enough",
      };
      break;
    case "firstName":
      isValid = regularExpressions[field].test(value);
      fieldValidation = {
        isValid,
        error: isValid ? "" : "Invalid first name",
      };
      break;
    case "middleName":
      isValid = true;
      fieldValidation = {
        isValid,
        error: "",
      };
      break;
    case "lastName":
      isValid = regularExpressions[field].test(value);
      fieldValidation = {
        isValid,
        error: isValid ? "" : "Invalid last name",
      };
      break;
    default:
      fieldValidation = {
        isValid: true,
        error: "",
      };
  }
  return fieldValidation;
};
