import { TextField } from "@material-ui/core";
import React from "react";

const MemberId = ({ handleFormChange, memberId, inputVariant, required }) => {
  return (
    <TextField
      id="register-relationship-memberId"
      label="Member ID"
      value={memberId.value || ""}
      variant={inputVariant}
      onChange={handleFormChange("relationship", "memberId")}
      error={memberId.isDirty && !memberId.isValid}
      helperText={memberId.error}
      required={required}
    />
  );
};

export default MemberId;
