import { useAuth0 } from "@auth0/auth0-react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    flexShrink: "0",
    flexGrow: "1",
    textAlign: "center",
    color: "#2c3f58",
    boxShadow: "4px 5px 12px rgba(67, 86, 100, 0.15) inset",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const RedirectBackDrop = () => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();

  return (
    <Paper className={classes.paper} role="main" id="main-content">
      <Backdrop className={classes.backdrop} open={!isAuthenticated}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
};

export default RedirectBackDrop;
