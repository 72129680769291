import { createTheme } from '@material-ui/core/styles'

// #0044ff
export const PHP_COLOR = {
    primary: {
        main: '#0057E7',
        light: '#80ABF3',
        medium: '#3379EC',
        dark: '#000B9B',
    },
    secondary: {
        main: '#FFA600',
        light: '#FFD280',
        medium: '#FFB833',
        dark: '#EB8C14',
    },
    white: '#FFFFFF',
    blue: {
        light: '#002C75'
    },
    font: {
        main: '#2C3F58',
        error: '#c83614',
        success: '#00B57D',
    }
}

export const DIGITAL_UI = {
    footer: '#0042BF',
    header: '#FFFFFF',
    hover: '#002C75',
    disabled: '#5C6B7A',
    status: {
        error: '#c83614',
        success: '#00B57D',
        warning: '#FFD422',
    },
    states: {
        active: '#0057E7'
    },
    typography: {
        primary: '#2C3F58',
        link: '#0057E7',
        dark: '#FFFFFF',
    }
}

export const PHP_FONT = {
    primary: {
        fontFamily: [
            'Inter',
            'ITCFranklinGothicStd',
            'ITCFranklinGothicStdDmCd',
            'sans-serif',
            'Helvetica',
            'Arial'
        ].join(','),
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1rem',
    },
    header: {
        fontFamily: [
            'ITCFranklinGothicStd',
            'ITCFranklinGothicStdDmCd',
            'Inter',
            'sans-serif',
            'Helvetica',
            'Arial'
        ].join(','),
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '2.8em',
        color: '#2c3f58',
    }
}

export const theme = createTheme({
    palette: {
        primary: {
            ...PHP_COLOR.primary,
        },
        secondary: {
            ...PHP_COLOR.secondary,
        },
        text: {
            primary: PHP_COLOR.font.main,
            secondary: PHP_COLOR.font.main,
        },
        error: {
            main: PHP_COLOR.font.error,
        },
        success: {
            main: PHP_COLOR.font.success,
        }
    },
    typography: {
        fontFamily: [
            'Inter',
            'ITCFranklinGothicStd',
            'ITCFranklinGothicStdDmCd',
            // 'Robot', 
            'Helvetica',
            'sans-serif',
            'Arial'
        ].join(','),
    },
    overrides: {
        MuiTypography: {
            h2: {
                ...PHP_FONT.header,
                color: PHP_COLOR.font.main,
            },
            h5: {
                color: PHP_COLOR.font.main,
            },
            subtitle1: {
                color: PHP_COLOR.font.main,
            },
            // body2: {
            // color: PHP_COLOR.font.error,
            // }
        },
        MuiButton: {
            contained: {
                ...PHP_FONT.primary,
                backgroundColor: PHP_COLOR.primary.main,
                borderRadius: 28,
                border: `2px solid ${PHP_COLOR.primary.main}`,
                height: 55,
                width: 180,
                padding: [[11, 36]],
                color: PHP_COLOR.white,
                '&:hover, &:focus, &:active, &:visited': {
                    backgroundColor: DIGITAL_UI.hover + " !important",
                    border: `2px solid ${DIGITAL_UI.hover}`,
                },
                '&:disabled': {
                    backgroundColor: DIGITAL_UI.disabled,
                    border: DIGITAL_UI.disabled,
                    color: PHP_COLOR.white,
                },
                '@media only screen and (max-width: 650px)': {
                    fontSize: '0.6rem'
                },
            },
            outlined: {
                ...PHP_FONT.primary,
                backgroundColor: PHP_COLOR.white,
                borderRadius: 28,
                border: `2px solid ${PHP_COLOR.primary.main}`,
                height: 55,
                width: 180,
                padding: [[11, 36]],
                color: PHP_COLOR.primary.main,
                '&:hover, &:focus, &:active, &:visited': {
                    backgroundColor: '#DAE7FB', // PHP_COLOR.primary.light,
                    border: `2px solid ${DIGITAL_UI.hover}`,
                    color: DIGITAL_UI.hover,
                },
                '&:disabled': {
                    border: `2px solid ${DIGITAL_UI.disabled}`,
                    color: DIGITAL_UI.disabled,
                },
                '@media only screen and (max-width: 650px)': {
                    fontSize: '0.6rem'
                },
            },
        },
    },
})
