import { TextField } from "@material-ui/core";
import React from "react";

const FirstName = ({ handleFormChange, firstName, inputVariant, required, handleBlur }) => {
  return (
    <TextField
      id="register-relationship-firstName"
      label="First Name"
      value={firstName.value || ""}
      variant={inputVariant}
      onChange={handleFormChange("relationship", "firstName")}
      onBlur={() => handleBlur(firstName.value, "firstName")}
      error={firstName.isDirty && !firstName.isValid}
      helperText={firstName.error}
      required={required}
    />
  );
};

export default FirstName;
