import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import DisplayUserInfo from "../DisplayUserInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: [[0, theme.spacing(2)]],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    "& > *": {
      marginBottom: theme.spacing(2),
      width: "100%",
    },
    "& > :last-child": {
      marginBottom: 0,
    },
  },
}));

const Review = ({ dataToDisplay: { relationship, createLogin } }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        {/* <Typography variant='h5' component='h1' gutterBottom>Review Information</Typography> */}
        <Typography variant="subtitle1" component="h2" gutterBottom>
          Please review the information you've provided for your new
          myProvidence account.
        </Typography>
        <Typography variant="subtitle1" component="h2" gutterBottom>
          Once submitted, some information - including your date of birth and
          username cannot be changed. If you need to edit your information,
          click the Back button to return to the previous page.
        </Typography>
      </Paper>
      <Paper elevation={0}>
        <Typography variant="h5" component="h2" gutterBottom>
          Providence Relationship
        </Typography>
        <DisplayUserInfo label="relationship" data={relationship} />
      </Paper>
      <Paper elevation={0}>
        <Typography variant="h5" component="h2" gutterBottom>
          Login Information
        </Typography>
        <DisplayUserInfo label="createLogin" data={createLogin} />
      </Paper>
    </div>
  );
};

export default Review;
