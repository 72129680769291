import { TextField } from "@material-ui/core";
import React from "react";

const LastName = ({ handleFormChange, lastName, inputVariant, required, handleBlur }) => {
  return (
    <TextField
      id="register-relationship-firstName"
      label="Last Name"
      value={lastName.value || ""}
      variant={inputVariant}
      onChange={handleFormChange("relationship", "lastName")}
      onBlur={() => handleBlur(lastName.value, "lastName")}
      error={lastName.isDirty && !lastName.isValid}
      helperText={lastName.error}
      required={required}
    />
  );
};

export default LastName;
