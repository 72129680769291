import React from 'react'
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
        boxShadow: 'none',
        // boxShadow: '-4px 5px 12px rgba(67,86,100,0.15), -1px 2px 8px rgba(67,86,100,0.15)',
        // height: 200,
    },
    headerBox: {
        paddingTop: 50,
    },
    subHeaderBox: {

    },
    subheading: {
        fontFamily: 'Inter',
        fontSize: '1.5rem',
        fontWeight: 500,
        fontStyle: 'normal',
        letterSpacing: 1,
        wordBreak: 'break-word',
        // padding: theme.spacing(3, 4, 3, 4),
        padding: theme.spacing(5, 0, 0, 0),
        color: '#1E415B', // #2c3f58
        // '@media (min-width: 768px)': {
        //     fontSize: '1.5rem',
        //     fontWeight: 500,
        //     padding: theme.spacing(0, 4, 9, 4)
        // }
    },
}));

const WelcomeHeader = ({ heading1, heading2, subheading }) => {
    const classes = useStyles()
    return (
        <Paper square component="aside" id="welcome-header" classes={{ root: classes.root }}>
            <div className={classes.headerBox}>
                {
                    heading1 &&
                    <Typography variant="h2" component="h1" align="center" className={classes.heading}>
                        {heading1}
                    </Typography>
                }
                {
                    heading2 &&
                    <Typography variant="h2" component="h1" align="center" className={classes.heading}>
                        {heading2}
                    </Typography>
                }
            </div>
            {/* <div className={classes.subHeaderBox}> */}
                {
                    subheading &&
                    <Typography variant="h3" component="h2" align="center" className={classes.subheading}>
                        {subheading}
                    </Typography>
                }
            {/* </div> */}
        </Paper>
    )
}

export default WelcomeHeader

