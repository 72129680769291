import "./App.css";
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
// import config from './config'
import Layout from "./Component/Layout";
import RedesignedRegister from "./Component/RegisterRedesigned";
import PageNotFound from "./Component/PageNotFound";
import PrivateRoute from "./Component/PrivateRoute";
import Profile from "./View/Profile";
import CheckEmail from "./View/CheckEmail";

const App = (props) => {
  const [_isLoading, setIsLoading] = useState(false);
  const handleLoadingToggle = (loading) => setIsLoading(loading);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout _isLoading={_isLoading} setLoading={handleLoadingToggle} />
        }
      >
        {/* Public Routes */}
        <Route
          path="/"
          element={
            <RedesignedRegister
              _isLoading={_isLoading}
              setLoading={handleLoadingToggle}
            />
          }
        />
        <Route
          path="/checkemail"
          element={
            <CheckEmail
              _isLoading={_isLoading}
              setLoading={handleLoadingToggle}
            />
          }
        />

        {/* Private Routes */}
        <Route element={<PrivateRoute />}>
          <Route
            path="profile"
            element={
              <Profile
                _isLoading={_isLoading}
                setLoading={handleLoadingToggle}
              />
            }
          />
        </Route>

        {/* Not Found */}
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default App;
