import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles({
  tableContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
  },
  marginBottom: {
    marginBottom: 10,
  },
  table: {
    minWidth: 650,
  },
  tableSmall: {
    width: "80%",
  },
  tableHeader: {
    fontWeight: "bold",
  },
  tableCell: {
    border: "none",
  },
  overflowWrap: {
    overflowWrap: "anywhere"
  }
});

const DisplayUserInfo = ({
  label,
  data,
  dense,
  align,
  tableWidth,
  gutterBottom,
}) => {
  const classes = useStyles();
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={`${classes.tableContainer} ${
        gutterBottom ? classes.marginBottom : ""
      }`}
    >
      <Table
        aria-label={`user-information-${label}`}
        size={dense ? "small" : "medium"}
        className={`${tableWidth === "small" ? classes.tableSmall : ""}`}
      >
        <TableBody>
          {Object.keys(data).map((key) => (
            <TableRow key={key}>
              <TableCell
                className={`${classes.tableCell} ${classes.tableHeader}`}
              >
                {key}
              </TableCell>
              <TableCell
                className={`${classes.tableCell} dense ? ${classes.tableHeader} : '' ${classes.overflowWrap}`}
                align="left"
              >
                {data[key]}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DisplayUserInfo;
