import { TextField } from "@material-ui/core";
import React from "react";

const ZipCode = ({ handleFormChange, zipCode, inputVariant }) => {
  return (
    <TextField
      id="register-relationship-zipCode"
      label="Zip Code"
      autoComplete="postal-code"
      value={zipCode.value || ""}
      variant={inputVariant}
      onChange={handleFormChange("relationship", "zipCode")}
      error={zipCode.isDirty && !zipCode.isValid}
      helperText={zipCode.error}
      required
    />
  );
};

export default ZipCode;
