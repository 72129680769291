import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import { Outlet } from "react-router-dom";
import RedirectBackDrop from "./RedirectBackDrop";

const PrivateRoute = () => {
  return <Outlet />;
};

export default withAuthenticationRequired(PrivateRoute, {
  onRedirecting: () => <RedirectBackDrop />,
});
