import axios from "axios";
import { getRootURL } from "./utils";

const config = {};
export default config;

export const loadConfig = async () => {
  const configsFromConfigJSONFile = await axios(
    `${getRootURL()}/config.json`
  ).then((a) => a.data);
  for (let prop in config) {
    delete config[prop];
  }
  for (const prop in configsFromConfigJSONFile) {
    config[prop] = configsFromConfigJSONFile[prop];
  }
  return config;
};

// Was Unused.  Leaving here in case it's ne
// const loadConfigFromDotENV = () => {
//   for (const key in process.env) {
//     if (key.startsWith("REACT_APP_")) {
//       config[toCamelCase(key.replace("REACT_APP_", ""))] = process.env[key];
//     }
//   }
// };
