import "@fontsource/roboto";
import { loadConfig } from "./config";
import "./index.css";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { theme } from "./theme";

import App from "./App";
import Auth0ProviderWithHistory from "./auth/";

import { setConsoleDebug } from "./utils/utils";

// Enable/Disable Console.log debugging for entire application
setConsoleDebug(false);

(async () => {
  loadConfig()
    .then((res) => {
      render(
        // <React.StrictMode>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Auth0ProviderWithHistory>
              {/* <Auth0ProviderWithHistory config={config}> */}
              <Routes>
                <Route path="/*" element={<App />} />
                {/* <Route path="/*" element={<App config={config}/>} /> */}
              </Routes>
            </Auth0ProviderWithHistory>
          </Router>
        </ThemeProvider>,
        // </React.StrictMode>
        document.getElementById("root")
      );
    })
    .catch((err) => console.log("Error loading config: ", err));
})();
