import axios from "axios";
import config from "../config";
import { LOOKUP_TYPE_BY_ID, LOOKUP_TYPE_BY_NAME } from "../utils/constants";
import { consoleDebug } from "../utils/utils";

// api/customer/profile
// Check if the profile has already been created - By LastName/FirstName
export const validateProfile = async (formData, lookupType) => {
  consoleDebug("validateProfile -> formData: ", formData);
  consoleDebug("validateProfile -> lookupType: ", lookupType);
  const url = `${config.apiEndpoint}/${config.env}/customer/profile?`;

  let params;

  if (lookupType === LOOKUP_TYPE_BY_NAME) {
    params = `firstName=${formData.firstName.toUpperCase()}&lastName=${formData.lastName.toUpperCase()}`;
  } else if (lookupType === LOOKUP_TYPE_BY_ID) {
    params = `member=${formData.memberId}&group=${formData.groupId}`;
  }

  const fetchUrl = url + params;
  consoleDebug("api.validateProfileByName.fetchUrl: ", fetchUrl);

  return axios
    .get(fetchUrl)
    .then((res) => {
      consoleDebug("res.status: ", res.status);
      consoleDebug("res.data: ", res.data);
      if (res.status !== 200) {
        return 0;
      } else {
        return res.data;
      }
    })
    .catch((err) => {
      consoleDebug("Error validating customer profile: ", err);
      return false;
    });
};

// api/membershiprecord
// Check that member is eligible
export const validateMembershipRecord = async (formData, lookupType) => {
  consoleDebug("api.validateMembershipRecord.formData: ", formData);
  // Root api url
  const url = `${config.apiEndpoint}/${config.env}/membershiprecord?`;
  let params;

  if (lookupType === LOOKUP_TYPE_BY_NAME) {
    params = `firstName=${formData.firstName.toUpperCase()}&lastName=${formData.lastName.toUpperCase()}&dob=${
      formData.dateOfBirth
    }&zip=${formData.zipCode}`;
  } else if (lookupType === LOOKUP_TYPE_BY_ID) {
    params = `member=${formData.memberId}&group=${formData.groupId}&dob=${formData.dateOfBirth}&zip=${formData.zipCode}`;
  }

  const lookupUrl = url + params;
  consoleDebug("ValidateMemberShipRecord url:", lookupUrl);

  return axios
    .get(lookupUrl)
    .then((res) => {
      consoleDebug("res.status: ", res.status);
      consoleDebug("res.data: ", res.data);
      if (res.status !== 200) {
        return 0;
      } else {
        return res.data;
      }
    })
    .catch((err) => {
      consoleDebug("Error validating membership record: ", err);
      return false;
    });
};

// Check if email exists. Only allow users to register if call returns false, duplicate email
export const validateLoginRecord = async (email) => {
  // console.log('email: ', email)
  const url = `${config.apiEndpoint}/${
    config.env
  }/customer?email=${encodeURIComponent(email)}`;
  // console.log(`URL ==> ${url}`)
  return axios
    .get(url)
    .then((res) =>
      res.status !== 200 ? false : res.data === "true" || res.data === true
    )
    .catch((err) => false);
};

export const registerUser = async (userObj) => {
  consoleDebug("api.registerUser.userObj: ", userObj);

  const url = `${config.apiEndpoint}/${config.env}/customer`;
  consoleDebug("api.registerUser.url: ", url);
  return axios
    .post(url, JSON.stringify(userObj), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      consoleDebug("registerUser.res: ", res);
      if (res.status !== 200) {
        return false;
      } else {
        return res.data;
      }
    })
    .catch((err) => {
      consoleDebug("registerUser.err: ", err);
      return false;
    });
};

export const updateUserProfile = async (token, userObj) => {
  const url = `${config.apiEndpoint}/${config.env}/customer/profile`;
  // const url = `https://cors-anywhere.herokuapp.com/${API_CONFIG.apiEndpoint}/${API_CONFIG.env}/customer/profile`
  // console.log(`URL ==> ${url}`)
  return axios
    .post(url, JSON.stringify(userObj), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => (res.status !== 200 ? false : res.data))
    .catch((err) => false);
};

export const updateUserPassword = async (
  token,
  { memberId, groupId, password }
) => {
  const url = `${config.apiEndpoint}/${config.env}/customer/profile`;
  // const url = `https://cors-anywhere.herokuapp.com/${API_CONFIG.apiEndpoint}/${API_CONFIG.env}/customer/profile`
  // console.log(`URL ==> ${url}`)
  const body = {
    memberId,
    groupId,
    password,
  };
  return axios
    .post(url, JSON.stringify(body), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => (res.status !== 200 ? false : res.data))
    .catch((err) => false);
};
