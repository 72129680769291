import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import config from "../config";

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    width: "100%",
    height: "100%",
    paddingTop: theme.spacing(5),
    backgroundSize: "1.5em",
    backgroundImage: `linear-gradient(to bottom, white 25%, rgba(255,255,255,0.5) 60%, rgba(255,255,255,0.5) 95%), Url(https://www.providencehealthplan.com/Providence/Images/Website/repeater-plus-slate.svg)`,
    backgroundRepeat: "repeat",
    zIndex: 0,
    "@media only screen and (max-width: 900px)": {
      height: "550px",
    },
  },
  card: {
    // minHeight: 500,
    maxWidth: 750,
    minWidth: 300,
    width: "20%",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    fontWeight: 600,
    padding: [
      [theme.spacing(8), theme.spacing(5), theme.spacing(3), theme.spacing(5)],
    ],
    // '@media only screen and (max-width: 900px)': {
    // width: '70%',
    // minWidth: 0,
    // },
    "& > div": {
      margin: [[theme.spacing(1), 0]],
      minWidth: "10rem",
      maxWidth: "18rem",
      // width: '80%',
      // '@media only screen and (min-width: 600px)': {
      //     width: '50%',
      // },
    },
  },
  headingWrapper: {
    width: "300px",
  },
  heading: {
    fontSize: "2.5rem",
    fontWeight: 900,
    marginBottom: "30px",
  },
  subtitle: {
    height: "250px",
  },
  subtitle1: {
    marginBottom: "25px",
    fontWeight: 600,
  },
  subtitle2: {
    fontWeight: 600,
  },
  button: {
    whiteSpace: "nowrap",
  },
}));

const handleBackToMyProv = (event) => {
  event.preventDefault();
  // Below solution force user logout from the tenant and not just app logout.
  window.location.replace(
    `https://${config.auth0Domain}/v2/logout?client_id=${
      config.auth0ClientId
    }&returnTo=${encodeURI(config.myProvSamlUrl)}`
  );
  // window.location.replace(config.myProvSamlUrl)
  // axios.get(`https://${config.auth0Domain}/v2/logout?client_id=${config.auth0ClientId}&returnTo=${encodeURI(config.myProvSamlUrl)}`)
  return null;
};

const CheckEmail = ({ isLoading, setLoading }) => {
  const classes = useStyles();

  return (
    <article className={classes.contentWrapper}>
      <Card className={classes.card} raised>
        <div className={classes.headingWrapper}>
          <Typography
            variant="h3"
            component="h2"
            align="center"
            className={classes.heading}
            gutterBottom
          >
            Confirm your email address.
          </Typography>
        </div>
        <div className={classes.subtitle}>
          <Typography
            variant="subtitle1"
            component="h2"
            align="left"
            className={classes.subtitle1}
          >
            Before continuing, we need you to confirm your email address.
          </Typography>
          <Typography
            variant="subtitle1"
            component="h2"
            align="left"
            className={classes.subtitle2}
          >
            Please check your inbox for the verification email we just sent to
            the email address you registered with.
          </Typography>
        </div>
        <Button
          variant="contained"
          className={classes.button}
          onClick={handleBackToMyProv}
        >
          Return to login
        </Button>
      </Card>
      {/* <h3>myProvSamlUrl:{ config.myProvSamlUrl }</h3> */}
    </article>
  );
};

export default CheckEmail;
