import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { Typography, Link } from '@material-ui/core'
import { DIGITAL_UI } from '../theme'
import config from '../config'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: DIGITAL_UI.footer || '#0042BF',
        color: '#ffffff',
        width: '100%',
        height: '100%',
        padding: [[20, 0]],
        // height: 231,
        // height: '35vh',
        // smartphones
        // '@media only screen and (max-width: 320px)': {        
        // },
        // tablets
        // '@media only screen and (max-width: 768px)': {
        //     // height: '30vh',            
        // },
        '@media only screen and (min-width: 1024px)': {
            padding: 0,
            height: 231,
            flexShrink: 0,
        },
    },
    termsAndDisclaimer: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        cursor: 'pointer',        
    },
    copyright: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        fontFamily: 'Inter',
        fontSize: '0.85rem',
        padding: 10,  
        '@media only screen and (min-width: 1024px)': {
            display: 'flex',
            flexFlow: 'row wrap',
        }, 
    },
    addPipe: {
        '& > *': {           
            margin: theme.spacing(1),
        },  
        '@media only screen and (min-width: 1024px)': {
            '& > :after':{
                content: '" | "',
            },
            '& > :last-child:after':{
                content: '""',          
            },
        },
    },
}));

// const footerLinks = [
//     {
//         title: 'User Agreement',
//         // url: 'https://myprovidence.healthtrioconnect.com/public-app/content/member/payorPage.page?xsesschk=&pptitle=myprovidence-user-agreement',
//         url: 'https://www.providencehealthplan.com/user-agreement',
//     },
//     {
//         title: 'Terms of User & Privacy Policy',
//         url: 'https://www.providence.org/utility-pages/privacy-policy',
//     },
//     {
//         title: 'Notice of Privacy Practices',
//         url: 'https://www.providence.org/utility-pages/notice-of-privacy-practices',
//     },
//     {
//         title: 'Non-Discrimination and Communication Assistance',
//         url: 'https://www.providencehealthplan.com/non-discrimination-and-communication-assistance',
//     },
//     {
//         title: 'Contact Us',
//         url: 'https://myprovidence.healthtrioconnect.com/public-app/content/member/payorPage.page?xsesschk=&pptitle=contact-us-public',
//     }
// ]


const Footer = () => {
    const classes = useStyles()
    const { userAgreement, privacyPolicy, privacyPractices, nonDiscrimination, contactUs } = config
    const footerLinks = [
        {
            title: 'User Agreement',
            url: userAgreement,
        },
        {
            title: 'Terms of User & Privacy Policy',
            url: privacyPolicy,
        },
        {
            title: 'Notice of Privacy Practices',
            url: privacyPractices,
        },
        {
            title: 'Non-Discrimination and Communication Assistance',
            url: nonDiscrimination,
        },
        {
            title: 'Contact Us',
            url: contactUs,
        }
    ]

    return (
        <Paper className={classes.root} square role="contentinfo" id="footer" elevation={1}component='footer' >
            <div className={classes.termsAndDisclaimer}>
                <Typography variant="h4" component="h2" align="center" className={`${classes.copyright} ${classes.addPipe}`} gutterBottom>
                    {footerLinks.map(footer => 
                        <Link
                            key={footer.title}
                            href={footer.url}
                            variant="inherit"
                            // TypographyClasses={{ 'root': classes.links }}
                            color={'inherit'} 
                            target='_blank' 
                            rel='noreferrer'>
                            { footer.title }
                        </Link>
                    )}
                </Typography>
            </div>
            <Typography variant="h4" component="h2" align="center" className={classes.copyright} gutterBottom>
                {/* Copyright &copy; 1997 - {new Date().getFullYear()} Providence Health & Services. All Rights Reserved. */}
                Copyright &copy; {new Date().getFullYear()} Providence Health Plan, Providence Plan Partners, and Providence Health Assurance. All Rights Reserved. 
            </Typography>
        </Paper>
    )
}

export default Footer
