import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from "@material-ui/core/Typography"
// import Link from "@material-ui/core/Link"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        '& > *': {
        //   marginBottom: theme.spacing(1),
        //   padding: theme.spacing(2),
          width: '100%',
        },
        '& > :last-child': {
            marginBottom: 0,
        },
    },
    backToLogin: {
        marginTop: 20,
        width: 'fit-content'
    }
}))

const ActivateYourAccount = ({ link }) => {
    const classes = useStyles()
    // console.log('link: ', link)
    return (
        <div id='activate-your-account' className={classes.root}>
            {/* <Typography variant='h5' component='h1' gutterBottom>Activating Your Account</Typography>   */}
            <Typography variant='subtitle1' component='h1' gutterBottom>You're almost done, just one more step.</Typography>
            <Typography variant='subtitle1' component='h1' gutterBottom>Check the email account that you used to register for the confirmation email we just sent you.</Typography>
            <Typography variant='subtitle1' component='h1' gutterBottom>After you click the activation link in that message, you're ready to access your myProvidence account!</Typography>
            {/* {link && <Link href={link}>{'Login'}</Link>} */}
            {/* {link && <Button className={classes.backToLogin} onClick={() => window.open(link, "_self")} color="primary" variant="outlined" data-testid='activateyouraccount-login'>Login</Button>} */}
            {<Button className={classes.backToLogin} onClick={() => window.open(link, "_self")}  variant="contained" data-testid='activateyouraccount-login'>Go back to Login</Button>}
            {/* <Button className={classes.backToLogin} onClick={() => window.location.replace(link)} color="primary" variant="outlined">Login</Button> */}
        </div>
     )
}

export default ActivateYourAccount