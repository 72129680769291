import { FormControl, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  LOOKUP_TYPE_BY_ID,
  LOOKUP_TYPE_BY_NAME,
} from "../../../../utils/constants";

const useStyles = makeStyles(() => ({
  select: {
    border: "none",
    padding: "2px",
    "&.withBorder": {
      outline: "2px solid #0057E7",
      outlineOffset: "4px",
    },
  },
}));

const LookupType = ({
  lookupType,
  handleLookupTypeChange,
  forceUseMemberId,
  handleUseMemberIDAndReset,
}) => {
  const classes = useStyles();

  const handleSelectByChange = (event) => {
    const selectedValue = event.target.value;
    handleLookupTypeChange(selectedValue);

    if (forceUseMemberId && selectedValue === LOOKUP_TYPE_BY_ID) {
      handleUseMemberIDAndReset();
    }
  };

  return (
    <FormControl fullWidth>
      <Select
        labelId="selectByIdOrNameLabel"
        id="selectByIdOrName"
        value={lookupType || "Select Lookup Option"}
        label=""
        onChange={handleSelectByChange}
        className={`${classes.select} ${forceUseMemberId ? "withBorder" : ""}`}
      >
        <MenuItem value={LOOKUP_TYPE_BY_NAME}>First Name / Last Name</MenuItem>
        <MenuItem value={LOOKUP_TYPE_BY_ID}>Member ID / Group ID</MenuItem>
      </Select>
    </FormControl>
  );
};

export default LookupType;
