import { TextField } from "@material-ui/core";

const GroupId = ({ handleFormChange, groupId, inputVariant, required }) => {
  return (
    <TextField
      id="register-relationship-groupId"
      label="Group ID"
      value={groupId.value || ""}
      variant={inputVariant}
      onChange={handleFormChange("relationship", "groupId")}
      error={groupId.isDirty && !groupId.isValid}
      helperText={groupId.error}
      required={required}
    />
  );
};

export default GroupId;
