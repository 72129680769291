import Chip from "@material-ui/core/Chip";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import config from "../../config";
import { LOOKUP_TYPE_BY_ID, LOOKUP_TYPE_BY_NAME } from "../../utils/constants";
import DisplayUserInfo from "../DisplayUserInfo";
import ReverseAlert from "../Icon/ReverseAlert";

const useStyles = makeStyles((theme) => ({
  alertChip: {
    margin: [[18, 0]],
    width: "100%",
    "& .MuiChip-icon": {
      margin: [[2, 0]],
    },
    "& .MuiChip-label": {
      color: "#2C3F58",
    },
  },
}));

const AccountFound = ({
  dataToDisplay: {
    groupId,
    memberId,
    firstName,
    lastName,
    dateOfBirth,
    zipCode,
  },
  lookupType,
  multipleAcctsFound,
}) => {
  const classes = useStyles();

  let userInfo;

  if (lookupType === LOOKUP_TYPE_BY_ID) {
    userInfo = {
      "ID Number": memberId.value || "",
      "Group Number": groupId.value || "",
    };
  } else if (lookupType === LOOKUP_TYPE_BY_NAME) {
    userInfo = {
      "Date of Birth": dateOfBirth.value,
      Name: `${firstName.value} ${lastName.value}`,
      "Zip Code": zipCode.value,
    };
  }

  return (
    <Paper elevation={0}>
      <Chip
        icon={<ReverseAlert style={{ fontSize: 20 }} />}
        label={
          <Typography variant="body2">
            {multipleAcctsFound ? (
              <>We are unable to match the member information to an account.</>
            ) : (
              <>
                The member information entered is already linked to an existing
                account.
              </>
            )}
          </Typography>
        }
        color="secondary"
        variant="outlined"
        className={classes.alertChip}
      />

      <DisplayUserInfo
        label="account-found"
        data={userInfo}
        dense
        align="center"
        tableWidth="small"
        gutterBottom
      />

      <ul>
        <li>
          <Typography variant="subtitle1" gutterBottom>
            {multipleAcctsFound ? (
              <>
                If you have your ID Card, or Member ID and Group ID, click
                "Start Over" to return to the start to attempt registration with
                your member information at hand.
              </>
            ) : (
              <>
                To change the above information, click the "Back" button below.
                Make the change, then resubmit your information.
              </>
            )}
          </Typography>
        </li>
      </ul>

      <Typography variant="subtitle1" gutterBottom>
        {/* To access an existing account: */}
        <b>If you think you may have already created an account:</b>
      </Typography>
      <ul>
        <li>
          <Typography variant="subtitle1" gutterBottom>
            <Link target="_blank" rel="noopener" href={config.myProvSamlUrl}>
              Go back to the login page
            </Link>
          </Typography>
        </li>
        <li>
          <Typography variant="subtitle1" gutterBottom>
            Call the myProvidence help desk at 503-216-6463 or 877-569-7768 for
            help remembering your password or the email address you registered
            with.
          </Typography>
        </li>
      </ul>
    </Paper>
  );
};

export default AccountFound;
