import { detect } from 'detect-browser'

export const parseQueryString = (queryString) => {
    // '?state=hKFo2SBwRVNXaS1HbzQ2a0hvVTZQQXFMa2hXdGxDa1RGcnh1daFupWxvZ2luo3RpZNkgQUEzTHZqZ3RWMVNJWkthWVBIU3FzVHFueE1rUUZXSnKjY2lk2SBxNXhIejVraFZGVk1NSGR6c0doOUhzV0VaamJkM09WRg&appName=Patient%20Access%20API'
    let queryStr = queryString.indexOf('?') === 0 ? queryString.slice(1) : queryString
    return queryStr
    .split('&')
    .map(a => a.split('='))
    .reduce((a,b) => {
        const [key, val] = b.map(decodeURIComponent)
        a[key] = val
        return a
    }, {})
}

export const _isBrowserBraveOrSafari = () => {
    const detectBrowser = detect()
    if (detectBrowser && detectBrowser?.type === 'browser' && detect?.name) {
      if (detectBrowser.name === 'safari' || window?.navigator?.brave) {
        return true
      }
    }
    // return true
    return false
  }  

export const _cacheLocation = () => {
    if (_isBrowserBraveOrSafari()) {
      return 'localstorage'
    }
    // return 'localstorage'
    return ''
  }

// console.log(parseQueryString('?state=hKFo2SBwRVNXaS1HbzQ2a0hvVTZQQXFMa2hXdGxDa1RGcnh1daFupWxvZ2luo3RpZNkgQUEzTHZqZ3RWMVNJWkthWVBIU3FzVHFueE1rUUZXSnKjY2lk2SBxNXhIejVraFZGVk1NSGR6c0doOUhzV0VaamJkM09WRg&appName=Patient%20Access%20API'))

export const getRootURL = () => (
  window.location?.origin ?? `${window.location?.protocol}//${window.location?.host}`
)

export const toCamelCase = (str = '') => {
  return str
     .replace(/[^a-z0-9]/gi, ' ')
     .toLowerCase()
     .split(' ')
     .map((el, ind) => ind === 0 ? el : el[0].toUpperCase() + el.substring(1, el.length))
     .join('');
}

export const millisecondsToHMS = (milliseconds) => {
  const seconds = Math.floor((milliseconds / 1000) % 60);
  const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
  const hours = Math.floor((milliseconds / 1000 / 60 / 60) % 24);

  return [
    hours.toString().padStart(2, "0"),
    minutes.toString().padStart(2, "0"),
    seconds.toString().padStart(2, "0")
  ].join(":");
}