// ***********************************
// CONSOLE.LOG DEBUGGING FUNCTIONALITY
// ***********************************
// Turn on or off console.log debugging
let debug = false;

// Set debugging on/off globally
export const setConsoleDebug = (value) => {
  debug = value;
};

// Console logging function
export const consoleDebug = (message, ...params) => {
  if (debug) {
    console.log(message, ...params);
  }
};

// *************************
// SET APPLICATION TEST MODE
// *************************
let testMode = false;
export const isTestMode = () => testMode;

export const setTestMode = (value) => {
  testMode = value;
};
