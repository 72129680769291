export const LOOKUP_TYPE_BY_ID = "MemberID";
export const LOOKUP_TYPE_BY_NAME = "Name";

export const DEFAULT_FORM_FIELDS = {
  relationship: {
    groupId: { isDirty: false, isValid: false, error: "", value: "" },
    memberId: { isDirty: false, isValid: false, error: "", value: "" },

    firstName: { isDirty: false, isValid: false, error: "", value: "" },
    lastName: { isDirty: false, isValid: false, error: "", value: "" },

    dateOfBirth: { isDirty: false, isValid: false, error: "", value: null },
    zipCode: { isDirty: false, isValid: false, error: "", value: "" },
  },
  createLogin: {
    email: { isDirty: false, isValid: false, error: "", value: "" },
    password: { isDirty: false, isValid: false, error: "", value: "" },
  },
};
