import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import { useState } from "react";

const DateOfBirth = ({ dateOfBirth, handleFormChange }) => {
  // These needs to pass to parent as function,  not localState.
  const [selectedDate, setSelectedDate] = useState(dateOfBirth.value);
  const [invalidDateMsg, setInvalidDateMsg] = useState("");
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setInvalidDateMsg("Invalid Date Format");
    if (date && date !== "Invalid Date" && isValid(date)) {
      setInvalidDateMsg("");
      handleFormChange(
        "relationship",
        "dateOfBirth"
      )(format(date, "MM/dd/yyyy"));
      setIsDatePickerOpen(false);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        required
        // autoFocus
        placeholder="MM/DD/YYYY"
        // onFocus={handleOnfocusDatePicker}
        disableToolbar
        disableFuture
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id="dob-inline"
        label="Date of Birth"
        // label="Date of Birth (mm/dd/yyyy)"
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "Date of Birth",
          // 'aria-label': 'Date of Birth (mm/dd/yyyy)',
        }}
        PopoverProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }}
        InputAdornmentProps={{
          position: "end",
          // onClick: handleCalendarIconClick
        }}
        animateYearScrolling
        error={
          invalidDateMsg.length > 0 ||
          (dateOfBirth.isDirty && !dateOfBirth.isValid)
        }
        helperText={invalidDateMsg || dateOfBirth.error}
        open={isDatePickerOpen}
        onOpen={() => setIsDatePickerOpen(true)}
        onClose={() => setIsDatePickerOpen(false)}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateOfBirth;
