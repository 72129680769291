import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { Fragment, useEffect, useState } from "react";

import {
  registerUser,
  validateLoginRecord,
  validateMembershipRecord,
  validateProfile,
} from "../../api";
import AccountFound from "../RegisterRedesigned/AccountFound";
import ActivateYourAccount from "../RegisterRedesigned/ActivateYourAccount";
import registrationSteps from "../RegisterRedesigned/registrationSteps.json";
import Review from "../RegisterRedesigned/Review";
import Step2 from "../RegisterRedesigned/Step2";
import { validateRegistrationForm } from "../validateForm";
import WelcomeHeader from "../WelcomeHeader";

import config from "../../config";
import {
  DEFAULT_FORM_FIELDS,
  LOOKUP_TYPE_BY_ID,
  LOOKUP_TYPE_BY_NAME,
} from "../../utils/constants";
import { consoleDebug, isTestMode, setTestMode } from "../../utils/utils";
import formatMemberId from "../../utils/formatmemberid";
import VerificationForm from "./VerificationForm";

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    width: "100%",
    height: "100%",
    paddingTop: theme.spacing(5),
    // backgroundSize: '1.5em',
    // backgroundImage: `linear-gradient(to bottom, white 5%, rgba(255,255,255,0.5) 25%, rgba(255,255,255,0.5) 95%, white 98%), Url(https://www.providencehealthplan.com/Providence/Images/Website/repeater-plus-slate.svg)`,
    // backgroundRepeat: 'repeat',
    zIndex: 0,
  },
  card: {
    minHeight: 500,
    maxWidth: 750,
    minWidth: 300,
    width: "90%",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    padding: [[theme.spacing(3), theme.spacing(5)]],
    "@media only screen and (max-width: 900px)": {
      width: "70%",
      minWidth: 0,
    },
    "@media only screen and (max-width: 600px)": {
      width: "90%",
      padding: "20px",
      minWidth: 0,
    },
  },
  root: {
    background: `none repeat 0 0 #FFFFFF`,

    "& > *": {
      paddingBottom: theme.spacing(2),
    },
    "@media only screen and (min-width: 361px)": {
      width: 792,
      padding: 15,
    },
  },
  subGrid: {
    width: "100%",
    paddingTop: theme.spacing(6),
    "@media only screen and (min-width: 768px)": {
      // width: '80%'
    },
    "@media only screen and (min-width: 1024px)": {
      // width: '50%'
    },
  },
  stepper: {
    padding: [[20, 0]],
  },
  steplabel: {
    // '& .MuiStepIcon-text': {
    //     fill: 'black !important',
    // },
  },
  formNavigation: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "center",
    padding: [[theme.spacing(6), 0, theme.spacing(7), 0]],
  },
  button: {
    margin: "10px",
  },
  submitButton: {
    // backgroundColor: '#00B57D'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

setTestMode(false);

let counter = 0;

// TODO: Need Message for "too many records found?"
let errorMsgById = `We're sorry. We are unable to verify your identity with the information you have provided. Please check the information against your member ID card. If you need additional assistance, please contact myProvidence help desk at 503-216-6463 or 877-569-7768.`;
let errorMsgByName = `We're sorry. We are unable to verify your identity with the information you have provided. Please make sure you are entering your name exactly as provided at enrollment.`;
let errorMsgEmailInUse =
  "The email you entered is already in use. Please select another email address to register with.";
let errorFailRetryMsgId = `We're sorry. We are still unable to verify your identity with the information you have provided. Please contact myProvidence help desk at 503-216-6463 or 877-569-7768 for registration assistance.`;
let errorFailRetryMsgName = `We're sorry. We are unable to verify your identity with the information you have provided. Try signing up with your Member ID or contact the myProvidence help desk at 503-216-6463 or 877-569-7768 for registration assistance.`;
const failedToCreateAProfile = `Something went wrong. Failed to create account.`;

const getRegistrationSteps = () => Object.values(registrationSteps);

const getActiveFormName = (activeStep) =>
  activeStep < 2 ? (activeStep === 0 ? "relationship" : "createLogin") : "";

const RedesignedRegister = ({ isLoading, setLoading }) => {
  const classes = useStyles();

  const steps = getRegistrationSteps();
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState(DEFAULT_FORM_FIELDS);
  const [formValid, setFormValid] = useState({
    relationship: false,
    createLogin: false,
  });
  const [userAgreement, setUserAgreement] = useState(false);
  const [showError, setShowError] = useState("");
  const [hideLookupUI, setHideLookupUI] = useState(false);
  const [showAccountFoundUI, setShowAccountFoundUI] = useState(false);
  const [auth0State, setAuth0State] = useState(
    `${window.location.origin}/profile`
  );
  const [lookupType, setLookupType] = useState(LOOKUP_TYPE_BY_ID);
  const [multipleAcctsFound, setMultipleAcctsFound] = useState(false);

  useEffect(() => {
    setAuth0State(config.myProvSamlUrl);
  }, []);

  // Validate the forms fields when they change
  useEffect(() => {
    const activeForm = getActiveFormName(activeStep);

    if (
      activeStep < 2 &&
      (activeForm === "relationship" || activeForm === "createLogin")
    ) {
      let isValid = true;

      // If they are looking up by FirstName/LastName, we don't want to validate MemberId/GroupID.
      // Opposite if they are looking up by MemberId/GroupID
      if (lookupType === LOOKUP_TYPE_BY_NAME) {
        const fieldsToValidate = [
          "zipCode",
          "dateOfBirth",
          "firstName",
          "lastName",
        ];

        for (const key of fieldsToValidate) {
          if (formData[activeForm].hasOwnProperty(key)) {
            if (!formData[activeForm][key].isValid) {
              isValid = false;
              break;
            }
          }
        }
      } else if (lookupType === LOOKUP_TYPE_BY_ID) {
        const fieldsToValidate = [
          "zipCode",
          "dateOfBirth",
          "memberId",
          "groupId",
        ];

        for (const key of fieldsToValidate) {
          if (formData[activeForm].hasOwnProperty(key)) {
            if (!formData[activeForm][key].isValid) {
              isValid = false;
              break;
            }
          }
        }
      } else {
        isValid = false;
      }

      isValid =
        activeForm === "createLogin" ? isValid && userAgreement : isValid;

      setFormValid({ [activeForm]: isValid });
    }
  }, [formData, activeStep, userAgreement, lookupType]);

  const handleLookupTypeChange = (newLookupType) => {
    setLookupType(newLookupType);
  };

  const handleUseMemberIdAndReset = () => {
    setLookupType(LOOKUP_TYPE_BY_ID);
    setActiveStep(0);
    counter = 0;
    setShowError("");
    setMultipleAcctsFound(false);
    setFormData((prevData) => ({
      ...prevData,
      groupId: "",
      memberId: "",
      firstName: "",
      lastName: "",
    }));
  };

  const handleStartOverClick = () => {
    setLookupType(LOOKUP_TYPE_BY_ID);
    setActiveStep(0);
    counter = 0;
    setShowError("");
    setMultipleAcctsFound(false);
    setFormData((prevData) => ({
      ...prevData,
      groupId: "",
      memberId: "",
      firstName: "",
      lastName: "",
    }));

    handleBack();
  };

  const getAccountFoundMessage = () => {
    if (multipleAcctsFound) {
      return "Important: Unable to Match Account";
    } else {
      return "Important: Existing Account Found";
    }
  };

  const handleBlur = (value, attribute) => {
    setFormData((prevData) => ({
      ...prevData,
      relationship: {
        ...prevData.relationship,
        [attribute]: {
          ...prevData.relationship[attribute],
          value: value?.trim(),
        }
      }
    }))
  }

  const handleFormChange = (form, prop) => (event) => {
    if (prop === "userAgreement") {
      setUserAgreement(event.target.checked);
      return;
    }

    let value = prop === "dateOfBirth" ? event : event.target.value;

    setFormData({
      ...formData,
      [form]: {
        ...formData[form],
        [prop]: {
          ...formData[form][prop],
          isDirty: true,
          ...validateRegistrationForm(prop, value),
          value,
        },
      },
    });
  };

  const handleBack = () => {
    consoleDebug("back clicked!  showAccountFoundUI: ", showAccountFoundUI);
    setShowAccountFoundUI(false);
    return setActiveStep(activeStep > 0 ? activeStep - 1 : 0);
  };

  const handleRelationshipForm = async () => {
    consoleDebug("handleRelationshipForm called, lookupType: ", lookupType);
    const {
      groupId: { value: groupId },
      dateOfBirth: { value: dateOfBirth },
      zipCode: { value: zipCode },
    } = formData.relationship;

    const memberId = formatMemberId(formData.relationship.memberId.value)
    const firstName = formData.relationship.firstName.value.trim()
    const lastName = formData.relationship.lastName.value.trim()
    
    let validateMembershipRecordResult;

    if (lookupType === LOOKUP_TYPE_BY_NAME) {
      if (firstName && lastName && dateOfBirth && zipCode) {
        validateMembershipRecordResult = await validateMembershipRecord(
          {
            firstName,
            lastName,
            dateOfBirth,
            zipCode,
          },
          lookupType
        );

        consoleDebug(
          "validateMembershipRecordResult by name: ",
          validateMembershipRecordResult
        );
      }
    } else if (lookupType === LOOKUP_TYPE_BY_ID) {
      if (memberId && groupId && dateOfBirth && zipCode) {

        const formattedMemberId = formatMemberId(memberId)

        validateMembershipRecordResult = await validateMembershipRecord(
          {
            memberId: formattedMemberId,
            groupId,
            dateOfBirth,
            zipCode,
          },
          lookupType
        );

        consoleDebug(
          "validateMembershipRecordResult by name: ",
          validateMembershipRecordResult
        );
      }
    } else {
      consoleDebug("Error with Lookup Type? Its const so shouldn't happen");
    }

    let validateProfileResult;

    if (
      !validateMembershipRecordResult ||
      validateMembershipRecordResult === 0
    ) {
      return {
        facetsAccExists: false,
        myProvAccExists: false,
      };
    } else {
      if (validateMembershipRecordResult > 1) {
        setMultipleAcctsFound(true);
        validateProfileResult = validateMembershipRecordResult;
      }

      if (validateMembershipRecordResult === 1) {
        // get /customer/profile
        if (lookupType === LOOKUP_TYPE_BY_NAME) {
          validateProfileResult = await validateProfile(
            {
              firstName,
              lastName,
            },
            lookupType
          );
        } else if (lookupType === LOOKUP_TYPE_BY_ID) {

          const formattedMemberId = formatMemberId(memberId)

          validateProfileResult = await validateProfile(
            {
              memberId: formattedMemberId,
              groupId,
            },
            lookupType
          );
        }
      }

      return {
        facetsAccExists: true,
        myProvAccExists: validateProfileResult,
      };
    }
  };

  const handleCreateLoginForm = async () => {
    // Step 1: Check if email already exists
    if (
      !formData.createLogin.email.value ||
      formData.createLogin.email.value.length < 1
    ) {
      return false;
    }
    return await validateLoginRecord(formData.createLogin.email.value);
  };

  // Next Button Click Handler
  const handleNext = async () => {
    setShowError("");
    setLoading(true);

    if (!isTestMode()) {
      if (activeStep === 0) {
        // First Form - Member Lookup

        const handleRelationshipFormResult = await handleRelationshipForm();

        consoleDebug(
          "handleRelationshipFormResult: ",
          handleRelationshipFormResult
        );

        if (multipleAcctsFound) {
          consoleDebug(
            "HandleNext -> Multiple Accounts were found. Count: ",
            counter
          );
          return;
        }

        if (!handleRelationshipFormResult.facetsAccExists) {
          counter++;

          consoleDebug(
            "form.FacetsAcc Does Not Exist. Fail Counter: ",
            counter
          );

          if (counter >= 3) {
            if (lookupType === LOOKUP_TYPE_BY_NAME) {
              setShowError(errorFailRetryMsgName);
            } else {
              setHideLookupUI(true);
              setShowError(errorFailRetryMsgId);
            }

            setLoading(false);
          } else {
            if (lookupType === LOOKUP_TYPE_BY_NAME) {
              setShowError(errorMsgByName);
            } else {
              setShowError(errorMsgById);
            }

            setLoading(false);
          }
          return;
        }
        if (handleRelationshipFormResult.myProvAccExists) {
          consoleDebug("myProvAcctExists");
          setShowAccountFoundUI(true);
          setLoading(false);
          return;
        }
      }
      if (activeStep === 1) {
        // Second Form - Create Login
        if (await handleCreateLoginForm()) {
          setShowError(errorMsgEmailInUse);
          setLoading(false);
          return;
        }
        setShowError("");
        setLoading(false);
      }
      if (activeStep === 2) {
        // third form
        // console.log('Step 3: Review')
      }
      if (activeStep === 3) {
        // fourth form
        // console.log('Step 4: Activate')
      }
    }
    setActiveStep(activeStep < steps.length - 1 ? activeStep + 1 : activeStep);
    setLoading(false);
  };

  const createMergedUserObj = () => {
    return {
      memberId: formatMemberId(formData.relationship.memberId.value),
      password: formData.createLogin.password.value,
      groupId: formData.relationship.groupId.value,
      firstName: formData.relationship.firstName.value,
      lastName: formData.relationship.lastName.value,
      dateOfBirth: formData.relationship.dateOfBirth.value,
      zipCode: formData.relationship.zipCode.value,
      email: formData.createLogin.email.value,
    };
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    const registerUserResult = await registerUser(createMergedUserObj());
    consoleDebug("register user result: ", registerUserResult);
    if (!registerUserResult) {
      setShowError(failedToCreateAProfile);
      setLoading(false);
      return;
    }
    setShowError("");
    consoleDebug("window.location.search: ", window.location.search);
    setActiveStep(activeStep < steps.length - 1 ? activeStep + 1 : activeStep);
    setLoading(false);
  };

  const checkIfForceUseMemberId = (lookupType, step, counter) => {
    const useMemberId =
      lookupType === LOOKUP_TYPE_BY_NAME && step === 0 && counter === 3;
    return useMemberId;
  };

  const loadRegisterSteps = (step) => {
    switch (step) {
      case 0:
        const forceUseMemberId = checkIfForceUseMemberId(
          lookupType,
          step,
          counter
        );

        if (hideLookupUI) {
          return null;
        }

        return !showAccountFoundUI ? (
          <VerificationForm
            inputVariant="standard"
            formData={formData["relationship"]}
            handleFormChange={handleFormChange}
            lookupType={lookupType}
            forceUseMemberId={forceUseMemberId}
            onLookupTypeChange={handleLookupTypeChange}
            handleUseMemberIdAndReset={handleUseMemberIdAndReset}
            handleBlur={handleBlur}
          />
        ) : (
          <AccountFound
            dataToDisplay={formData["relationship"]}
            lookupType={lookupType}
            multipleAcctsFound={multipleAcctsFound}
          />
        );
      case 1:
        return (
          <Step2
            inputVariant="standard"
            formData={formData["createLogin"]}
            userAgreement={userAgreement}
            handleFormChange={handleFormChange}
          />
        );
      case 2:
        let displayFields;

        if (lookupType === LOOKUP_TYPE_BY_ID) {
          displayFields = {
            relationship: {
              "Member ID": formData.relationship.memberId.value,
              "Group ID": formData.relationship.groupId.value,
              "Date of Birth": formData.relationship.dateOfBirth.value,
              "Zip Code": formData.relationship.zipCode.value,
            },
            createLogin: {
              Email: formData.createLogin.email.value,
            },
          };
        } else {
          displayFields = {
            relationship: {
              "First Name": formData.relationship.firstName.value,
              "Last Name": formData.relationship.lastName.value,
              "Date of Birth": formData.relationship.dateOfBirth.value,
              "Zip Code": formData.relationship.zipCode.value,
            },
            createLogin: {
              Email: formData.createLogin.email.value,
            },
          };
        }

        return <Review dataToDisplay={displayFields} />;
      case 3:
        return <ActivateYourAccount link={auth0State || ""} />;
      default:
        return (
          <VerificationForm
            inputVariant="standard"
            formData={formData["relationship"]}
            handleFormChange={handleFormChange}
          />
        );
    }
  };

  return (
    <Fragment>
      <WelcomeHeader
        heading1="Welcome to myProvidence!"
        heading2="Let’s get you registered."
        subheading={
          !showAccountFoundUI
            ? steps[activeStep]?.title
            : getAccountFoundMessage()
          // : "Important: Existing Account Found"
        }
      />
      <article className={classes.contentWrapper}>
        <Card id="registration-card" className={classes.card} raised>
          {!hideLookupUI && (
            <Grid item xs={12} className={classes.subGrid}>
              {loadRegisterSteps(activeStep)}
            </Grid>
          )}

          {showError.length > 0 && (
            <Grid item xs={12} id="error" className={classes.subGrid}>
              <Typography
                variant="body2"
                color="error"
                className={classes.formTitle}
              >
                {showError}
              </Typography>
            </Grid>
          )}
          {!hideLookupUI && activeStep < 3 && counter < 3 && (
            <Grid
              item
              xs={12}
              id="register-buttons"
              className={`${classes.subGrid} ${classes.formNavigation}`}
            >
              {multipleAcctsFound ? (
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleStartOverClick}
                >
                  Start Over
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={handleBack}
                  disabled={!showAccountFoundUI && activeStep < 1}
                >
                  Back
                </Button>
              )}

              {activeStep < 2 ? (
                !showAccountFoundUI && (
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={handleNext}
                    disabled={!formValid[getActiveFormName(activeStep)]}
                  >
                    Next
                  </Button>
                )
              ) : (
                <Button
                  variant="contained"
                  className={`${classes.button} ${classes.submitButton}`}
                  onClick={handleFormSubmit}
                >
                  Submit
                </Button>
              )}
            </Grid>
          )}
        </Card>
      </article>
    </Fragment>
  );
};

export default RedesignedRegister;
