import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";    

const useStyles = makeStyles(theme => ({
    paper: {
        flexShrink: '0',
        flexGrow: '1',
        textAlign: "center",
        color: '#2c3f58',
        boxShadow: '4px 5px 12px rgba(67, 86, 100, 0.15) inset',
    },
}));

const PageNotFound = () => {
    const classes = useStyles();

    return (
        <Paper className={classes.paper} role="main" id="main-content">
            <h1>404: Page Not Found! </h1>            
        </Paper>
    )        
};

export default PageNotFound;