import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
// import Slide from '@material-ui/core/Slide'
import MuiAlert from "@material-ui/lab/Alert";
import { Typography } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LockOpen from "@material-ui/icons/LockOpen";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import jwt_decode from "jwt-decode";

import config from "../config";
import { updateUserProfile, updateUserPassword } from "../api";
import WelcomeHeader from "../Component/WelcomeHeader";
import UserNotAuthenticated from "../Component/UserNotAuthenticated";
import PasswordPolicy from "../Component/PasswordPolicy";
import { validateRegistrationForm } from "../Component/validateForm";

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    width: "100%",
    height: "100%",
    paddingTop: theme.spacing(5),
    backgroundSize: "1.5em",
    // backgroundImage: `linear-gradient(to bottom, white 5%, rgba(255,255,255,0.5) 25%, rgba(255,255,255,0.5) 95%, white 98%), Url(https://www.providencehealthplan.com/Providence/Images/Website/repeater-plus-slate.svg)`,
    backgroundRepeat: "repeat",
    zIndex: 0,
  },
  container: {
    maxWidth: 750,
    minWidth: 300,
    width: "90%",
    display: "flex",
    flexFlow: "column nowrap",
    // '@media only screen and (max-width: 900px)': {
    //     width: '70%',
    //     minWidth: 0,
    // },
  },
  card: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    padding: [
      [theme.spacing(8), theme.spacing(5), theme.spacing(3), theme.spacing(5)],
    ],
    "& > div": {
      margin: [[theme.spacing(1), 0]],
      minWidth: "10rem",
      width: "80%",
      "@media only screen and (min-width: 600px)": {
        width: "50%",
      },
    },
  },
  subGrid: {
    width: "100% !important",
    padding: [[theme.spacing(6), 0, theme.spacing(6), 0]],
    display: "flex",
    flexFlow: "row",
    justifyContent: "center",
  },
  button: {
    margin: [[0, theme.spacing(1)]],
    width: "fit-content",
    // '@media only screen and (max-width: 900px)': {
    //     fontSize: '0.6rem'
    // },
    // '&:focus': {
    //     backgroundColor: 'white',
    // },
    // '&:active': {
    //     backgroundColor: 'red',
    // },
    // '&:hover': {
    //     backgroundColor: 'green',
    // }
  },
}));

const DEFAULT_PROFILE_FIELDS = {
  firstName: { isDirty: false, isValid: false, error: "", value: "" },
  middleName: { isDirty: false, isValid: false, error: "", value: "" },
  lastName: { isDirty: false, isValid: false, error: "", value: "" },
  email: { isDirty: false, isValid: false, error: "", value: "" },
};

const Profile = ({ _isLoading, setLoading, inputVariant = "standard" }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const { user, getAccessTokenSilently } = useAuth0();
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const [validProfile, setValidProfile] = useState(false);
  const [profileChanged, setProfileChanged] = useState(false);
  const [profileData, setProfileData] = useState(DEFAULT_PROFILE_FIELDS);
  const [password, setPassword] = useState({
    newPassword: {
      value: "",
      showPassword: false,
    },
    confirmNewPassword: {
      value: "",
      showPassword: false,
    },
    passwordMatch: false,
  });
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [sessionToken, setSessionToken] = useState(null);
  // const [auth0State, setAuth0State] = useState(null)
  const [userName, setUserName] = useState("");
  const [memberId, setMemberId] = useState("");
  const [groupId, setGroupId] = useState("");
  const [pendingUsernameDeprecation, setPendingUsernameDeprecation] =
    useState(false);
  const [snackBar, setSnackBar] = useState({
    open: false,
    // Transition: Slide,
    message: "",
    severity: "success",
    autoHideDuration: 6000,
  });

  const [isProfileFormValid, setIsProfileFormValid] = useState(false);

  useEffect(() => {
    if (!searchParams.has("session_token")) {
      // SINCE THE USER IS NOT COMING FROM MYPROV THEN LOOK FOR GROUP_ID AND MEMBER_ID IN CLAIMS, IDTOKEN
      if (
        !user[`${config.auth0AudienceIdentifier}groupId`] ||
        !user[`${config.auth0AudienceIdentifier}memberId`]
      ) {
        return;
      }
      setValidProfile(true);
      setUserName(user[`${config.auth0AudienceIdentifier}username`]);
      setGroupId(user[`${config.auth0AudienceIdentifier}groupId`]);
      setMemberId(user[`${config.auth0AudienceIdentifier}memberId`]);
      setPendingUsernameDeprecation(
        user[`${config.auth0AudienceIdentifier}pendingUsernameDeprecation`]
      );
      return;
    }
    setValidProfile(true);
    setSessionToken(searchParams.get("session_token"));
  }, [searchParams, user]);

  useEffect(() => {
    if (!sessionToken) return;
    const decodedToken = jwt_decode(sessionToken);
    setUserName(decodedToken?.username);
    setGroupId(decodedToken?.groupId);
    setMemberId(decodedToken?.memberId);
    setPendingUsernameDeprecation(decodedToken?.pendingUsernameDeprecation);
  }, [sessionToken]);

  useEffect(() => {
    if (!memberId || !groupId) return;
    setProfileData(convertUserObjToDisplayFields(user));
  }, [user, memberId, groupId]);

  useEffect(() => {
    if (
      !password.newPassword.value.length ||
      !password.confirmNewPassword.value.length
    )
      return;
    setPassword({
      ...password,
      passwordMatch:
        password.newPassword.value === password.confirmNewPassword.value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password.newPassword, password.confirmNewPassword]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const convertUserObjToDisplayFields = (user) => {
    const middleName =
      user.name.split(" ").length > 2 ? user.name.split(" ")[1] : "";
    const email = /^fake\+.*@providencehealthplans.com.test$/.test(user?.email)
      ? ""
      : user?.email;
    const firstNameValidation = validateRegistrationForm(
      "firstName",
      user?.given_name
    );
    const middleNameValidation = validateRegistrationForm(
      "middleName",
      middleName
    );
    const lastNameValidation = validateRegistrationForm(
      "lastName",
      user?.family_name
    );
    const emailValidation = validateRegistrationForm("email", email);
    return {
      firstName: {
        value: user.given_name ?? "",
        isDirty: false,
        ...firstNameValidation,
      },
      middleName: {
        value: middleName,
        isDirty: false,
        ...middleNameValidation,
      },
      lastName: {
        value: user.family_name ?? "",
        isDirty: false,
        ...lastNameValidation,
      },
      email: {
        value: email,
        isDirty: false,
        ...emailValidation,
      },
    };
  };

  useEffect(() => {
    setIsProfileFormValid(
      Object.entries(profileData).reduce(
        (prev, curr) => prev && curr[1].isValid,
        true
      )
    );
  }, [profileData, profileChanged]);

  const handleProfileChange = (field) => (event) => {
    let newProfile = {
      ...profileData,
      [field]: {
        ...profileData[field],
        isDirty: true,
        value: event.target.value.trim(),
        ...validateRegistrationForm(field, event.target.value.trim()),
      },
    };
    setProfileData(newProfile);
    setProfileChanged(userProfileChanged(newProfile));
  };

  const userProfileChanged = (newUser) => {
    let middleName =
      user.name.split(" ").length > 2 ? user.name.split(" ")[1] : "";
    if (
      user.given_name === newUser.firstName.value &&
      middleName === newUser.middleName.value &&
      user.family_name === newUser.lastName.value &&
      user.email === newUser.email.value
    ) {
      return false;
    }
    return true;
  };

  const updateUserObject = (newUserObj) => {
    const regexToReplaceExtraSpaces = /\s+/g;
    user["name"] =
      `${newUserObj?.firstName} ${newUserObj?.middleName} ${newUserObj?.lastName}`.replace(
        regexToReplaceExtraSpaces,
        " "
      );
    user["email"] = newUserObj?.email;
    setProfileData(convertUserObjToDisplayFields(user));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!isProfileFormValid) return;
      const accessToken = await getAccessTokenSilently();
      const userObj = {
        memberId: memberId,
        groupId: groupId,
        firstName: profileData.firstName.value,
        middleName: profileData.middleName.value,
        lastName: profileData.lastName.value,
        email: profileData.email.value,
        isDeprecated: userName.startsWith("profile#"),
      };
      const profileUpdate = await updateUserProfile(accessToken, userObj);
      if (profileUpdate) {
        updateUserObject(userObj);
        setPendingUsernameDeprecation(false);
        setSnackBar({
          open: true,
          message: "Profile updated!",
          // Transition: TransitionUp,
          severity: "success",
        });
      } else {
        // profile update wasn't successful
        setSnackBar({
          open: true,
          message: "Profile update failed!",
          // Transition: TransitionUp,
          severity: "error",
        });
      }
      return false;
    } catch (err) {
      console.error(err);
    }
  };

  const handleBackToMyProv = (event) => {
    event.preventDefault();
    return window.location.replace(config.myProvSamlUrl);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({
      ...snackBar,
      open: false,
      message: "",
      severity: "success",
    });
  };

  const handlePasswordChange = (field, value) => {
    setPassword({
      ...password,
      [field]: {
        ...password[field],
        value: value,
      },
    });
  };

  const handleClickShowPassword = (field) => {
    setPassword({
      ...password,
      [field]: {
        ...password[field],
        showPassword: !password[field].showPassword,
      },
    });
  };

  const submitPasswordChange = async (event) => {
    event.preventDefault();
    try {
      const accessToken = await getAccessTokenSilently();
      const userObj = {
        memberId: memberId,
        groupId: groupId,
        password: password.newPassword.value,
      };
      const passwordUpdate = await updateUserPassword(accessToken, userObj);
      if (passwordUpdate) {
        setSnackBar({
          open: true,
          message: "Password changed",
          // Transition: TransitionUp,
          severity: "success",
          autoHideDuration: 10000,
        });
        setPassword({
          newPassword: {
            value: "",
            showPassword: false,
          },
          confirmNewPassword: {
            value: "",
            showPassword: false,
          },
          passwordMatch: false,
        });
      } else {
        setSnackBar({
          open: true,
          message: "Failed to change password!",
          severity: "error",
          autoHideDuration: 10000,
        });
      }
      return false;
    } catch (err) {
      console.error(err);
    }
  };

  const handleIsPasswordValid = (a) => {
    setIsPasswordValid(a);
  };

  useEffect(() => {}, [isPasswordValid]);

  return validProfile ? (
    <Fragment>
      {pendingUsernameDeprecation ? (
        <WelcomeHeader
          heading2="We’ve made improvements to the site!"
          subheading="Let’s make sure your account is up to date."
        />
      ) : (
        <WelcomeHeader heading1="Your Profile" />
      )}
      <article className={classes.contentWrapper}>
        <Container className={classes.container}>
          <AppBar position="static">
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab
                icon={<AccountCircleIcon />}
                label="Profile"
                {...a11yProps(0)}
              />
              <Tab
                icon={<LockOpen />}
                label="Update Password"
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={tabValue} index={0}>
            <TextField
              id="profile-first-name"
              label="First Name"
              value={profileData?.firstName?.value || ""}
              variant={inputVariant}
              onChange={handleProfileChange("firstName")}
              // error={profileData?.firstName?.isDirty && !profileData?.firstName?.isValid}
              error={!profileData?.firstName?.isValid}
              helperText={profileData?.firstName?.error}
              required
            />

            <TextField
              id="profile-middle-initial"
              label="Middle Initial"
              value={profileData?.middleName?.value || ""}
              variant={inputVariant}
              onChange={handleProfileChange("middleName")}
              // error={profileData?.middleName?.isDirty && !profileData?.middleName?.isValid}
              error={!profileData?.middleName?.isValid}
              helperText={profileData?.middleName?.error}
            />

            <TextField
              id="profile-last-name"
              label="Last Name"
              value={profileData?.lastName?.value || ""}
              variant={inputVariant}
              onChange={handleProfileChange("lastName")}
              // error={profileData?.lastName?.isDirty && !profileData?.lastName?.isValid}
              error={!profileData?.lastName?.isValid}
              helperText={profileData?.lastName?.error}
              required
            />

            <TextField
              id="profile-email"
              label="Email"
              value={profileData?.email?.value || ""}
              variant={inputVariant}
              onChange={handleProfileChange("email")}
              // error={profileData?.email?.isDirty && !profileData?.email?.isValid}
              error={!profileData?.email?.isValid}
              helperText={profileData?.email?.error}
              required
            />
            {pendingUsernameDeprecation && (
              <Typography variant="subtitle1" component="h1" gutterBottom>
                <b>
                  Your username must be an email address. Is the email address
                  you’d like to use?
                </b>
              </Typography>
            )}
            <Grid
              container
              spacing={3}
              id="update-profile-buttons"
              className={classes.subGrid}
            >
              {/* item xs={12} id='update-profile-buttons' className={`${classes.subGrid}`} */}
              {/* <Grid item xs> */}
              <Button
                variant="contained"
                className={classes.button}
                onClick={handleSubmit}
                disabled={!isProfileFormValid}
              >
                {profileChanged ? "Update" : "Looks Good"}
              </Button>
              {/* </Grid>  
                                    <Grid item xs> */}
              <Button
                variant="contained"
                className={classes.button}
                onClick={handleBackToMyProv}
                disabled={pendingUsernameDeprecation}
              >
                Back to MyProv
              </Button>
              {/* </Grid> */}
            </Grid>

            <Typography variant="subtitle2" component="p" gutterBottom>
              <b>Tip:</b> If you have a family email address that needs to be
              used for more than one myProvidence account, you can insert a plus
              sign with numbers to allow for multiple instances of the email
              address (for example: firstnamelastname@gmail.com and
              firstnamelastname+1@gmail.com).
            </Typography>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">
                New Password
              </InputLabel>
              <Input
                id="standard-adornment-new-password"
                type={password.newPassword.showPassword ? "text" : "password"}
                value={password.newPassword.value}
                onChange={(e) =>
                  handlePasswordChange("newPassword", e.target.value)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("newPassword")}
                      // onMouseDown={handleMouseDownPassword('newPassword')}
                    >
                      {password.newPassword.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">
                Confirm New Password
              </InputLabel>
              <Input
                id="standard-adornment-confirm-password"
                type={
                  password.confirmNewPassword.showPassword ? "text" : "password"
                }
                value={password.confirmNewPassword.value}
                onChange={(e) =>
                  handlePasswordChange("confirmNewPassword", e.target.value)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleClickShowPassword("confirmNewPassword")
                      }
                      // onMouseDown={handleMouseDownPassword('confirmNewPassword')}
                    >
                      {password.confirmNewPassword.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {password.newPassword.value.length > 0 &&
                password.confirmNewPassword.value.length > 0 &&
                !password.passwordMatch && (
                  <FormHelperText error={true}>
                    Password don't match.
                  </FormHelperText>
                )}
            </FormControl>
            <PasswordPolicy
              password={password.newPassword.value}
              isValidPassword={handleIsPasswordValid}
            />
            <Grid
              container
              spacing={3}
              id="change-password-button"
              className={classes.subGrid}
            >
              <Button
                variant="contained"
                className={classes.button}
                onClick={submitPasswordChange}
                disabled={!isPasswordValid || !password.passwordMatch}
              >
                Change Password
              </Button>
            </Grid>
          </TabPanel>
        </Container>
      </article>
      <Snackbar
        open={snackBar?.open}
        onClose={handleSnackBarClose}
        // TransitionComponent={snackBar?.Transition}
        // message={snackBar?.message}
        // key={snackBar.Transition.name}
        autoHideDuration={snackBar?.autoHideDuration ?? 6000}
      >
        <Alert onClose={handleSnackBarClose} severity={snackBar?.severity}>
          {snackBar?.message}
        </Alert>
      </Snackbar>
    </Fragment>
  ) : (
    <UserNotAuthenticated />
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Card className={classes.card} raised>
          {children}
        </Card>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default Profile;
