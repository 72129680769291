import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useIdleTimer } from 'react-idle-timer'

import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import config from '../config'
import { millisecondsToHMS } from '../utils'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        fontSize: '12px',
    },
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        maxWidth: '500px',
        border: '1px solid red',
        height: '200px',
        width: '500px',
      },
    content: {
        flex: '1 0 auto',
    },
    action: {
        marginBottom: '25px' 
    },
    button: {
        margin: [[0, theme.spacing(1)]],
        // width: 'fit-content',
        fontSize: '12px',
        height: '45px',
        width: '179px',
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit/>
});

// const DIALOG_TYPE = ['extend session', 'end session']

const IdleTimer = () => {
    const classes = useStyles()
    const { isAuthenticated, logout } = useAuth0()
    const navigate = useNavigate()

    // const timeout = 0.2 * 60 * 1000
    // const extendSessionThresholdTime = 0.8 * 60 * 1000

    // const sessionTimeout = (parseInt(config.sessionTimeout) ?? 15) * 60 * 1000// 15 min
    const timeoutPrompt = 2 * 60 * 1000
    const timeout = ( parseInt(config.sessionTimeout) * 60 * 1000 ) - timeoutPrompt ?? (15 * 60 * 1000)  // 15 min
    // const timeout = (parseInt('3') * 60 * 1000) - timeoutPrompt ?? (15 * 60 * 1000)// 15 min

    // console.log('timeoutPrompt: ', timeoutPrompt)
    // console.log('timeout: ', timeout)

    const [showExtendSessionDialog, setShowExtendSessionDialog] = useState(false)
    const [showEndSessionDialog, setShowEndSessionDialog] = useState(false)
    const [startTimer, setStartTimer] = useState(false)
    const [remainingTime, setRemainingTime] = useState(timeoutPrompt)

    const handleShowExtendSessionDialog = () => {
        setStartTimer(true)
        setShowEndSessionDialog(false)
        setShowExtendSessionDialog(true)
    }

    const handleShowEndSessionDialog = () => {
        setStartTimer(false)
        setShowExtendSessionDialog(false)
        setShowEndSessionDialog(false)
        pause()
        logout()
        navigate('/profile', { replace: true })
    }

    const { start, reset, pause, getRemainingTime } = useIdleTimer({
        timeout,
        promptTimeout: timeoutPrompt,
        // debounce: 10000,
        throttle: 10000,
        eventsThrottle: 1000,
        onPrompt: handleShowExtendSessionDialog,
        onIdle: handleShowEndSessionDialog,
    })

    /* 
        1. If user is not logged in, then discard
        2. If user is logged in, then run the timer
    */
    useEffect(() => {
        if(!isAuthenticated) {
            pause()
            return
        }
        start()        
    }, [isAuthenticated, pause, start])

    useEffect(() => {
        if(!startTimer) return
        let interval = setInterval(() => {
            setRemainingTime(getRemainingTime())
        }, 1000)
        return () => clearInterval(interval)
    }, [startTimer, setRemainingTime, getRemainingTime]);


    const handleDialogClose = (e, closeReason) => {
        e.preventDefault()
        // console.log('closeReason: ', closeReason)
        if (closeReason !== 'backdropClick') {
            setShowExtendSessionDialog(false)
            setShowEndSessionDialog(false)
        }
    }

    const handleLogin = () => {
        navigate('/profile', { replace: true })
        setShowExtendSessionDialog(false)
        setShowEndSessionDialog(false)
        reset()
    }

    const handleExtendSession = () => {
        handleLogin()
    }

    const handleEndSession = () => {
        // logout({ returnTo: window.location.origin })
        // setShowExtendSessionDialog(false)
        // setShowEndSessionDialog(false)
        handleShowEndSessionDialog()
    }

    return (
        <>
            <Dialog
                open={showExtendSessionDialog}
                onClose={(e, closeReason) => handleDialogClose(e, closeReason)}
                disableEscapeKeyDown
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Session timeout warning</DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText>
                        Your session will expire in {millisecondsToHMS(remainingTime)}. <br/> Select "Extend Session" to extend your session.
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.action}>
                   <Button variant="contained" className={classes.button} onClick={handleExtendSession}>
                        Extend Session
                    </Button>
                    <Button variant="contained" className={classes.button} onClick={handleEndSession} autoFocus>
                        End Session
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showEndSessionDialog}
                onClose={(e, closeReason) => handleDialogClose(e, closeReason)}
                disableEscapeKeyDown
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Session expired</DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText>Session expired</DialogContentText>
                </DialogContent>
                <DialogActions className={classes.action}>
                    <Button variant="contained" className={classes.button} onClick={handleLogin}>
                        Login
                    </Button>
                    <Button variant="contained" className={classes.button} onClick={handleEndSession} autoFocus>
                        End Session
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default IdleTimer
