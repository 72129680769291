import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const ReverseAlert = (props) => (
    <SvgIcon {...props} viewBox="0 0 25 25">
        <path d="M13.75 6.25H11.25V11.25H6.25V13.75H11.25V18.75H13.75V13.75H18.75V11.25H13.75V6.25Z" fill="#5C6B7A"/>
        <path d="M12.5 0C5.6 0 0 5.6 0 12.5C0 19.4 5.6 25 12.5 25C19.4 25 25 19.4 25 12.5C25 5.6 19.4 0 12.5 0Z" fill="#FFD422"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.7163 4.44229L4.14195 16.7824C3.75724 17.4093 4.19978 18.2222 4.92565 18.2222H20.0743C20.8003 18.2222 21.2428 17.4093 20.858 16.7824L13.2837 4.44229C12.9217 3.85257 12.0783 3.85257 11.7163 4.44229ZM11.5556 9.33334C11.5556 8.84242 11.9784 8.44444 12.5 8.44444C13.0216 8.44444 13.4444 8.84242 13.4444 9.33334V12.8889C13.4444 13.3798 13.0216 13.7778 12.5 13.7778C11.9784 13.7778 11.5556 13.3798 11.5556 12.8889V9.33334ZM11.5556 15.5556C11.5556 15.0646 11.9784 14.6667 12.5 14.6667C13.0216 14.6667 13.4444 15.0646 13.4444 15.5556C13.4444 16.0465 13.0216 16.4444 12.5 16.4444C11.9784 16.4444 11.5556 16.0465 11.5556 15.5556Z" fill="white"/>
    </SvgIcon>
)

export default ReverseAlert