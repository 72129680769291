import React, { Fragment, useState } from "react"
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from "@material-ui/core/Typography"
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import config from '../../config'

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      '& > div': {
          marginBottom: theme.spacing(1),
      },
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formTitle: {
        marginBottom: 10,
        textAlign: 'center',
    },
    form: {
        fontFamily: 'Inter',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        '& > div': {
            margin: [[theme.spacing(1), 0]],
            minWidth: '10rem',            
            width: '80%',
            '@media only screen and (min-width: 600px)': {
                width: '50%',
            },
        },
        '& > div:last-child': {
            textAlign: 'center',
            '@media only screen and (min-width: 600px)': {
                width: '30rem',
            },
        },
    },
}));

const Step2 = ({ 
        formData: { 
            email,
            password
        },
        userAgreement,
        inputVariant, 
        handleFormChange
}) => {
    const classes = useStyles()
    const [showPassword, setValues] = useState(false)    

    const handleClickShowPassword = () => {
        setValues(!showPassword)
    }
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    return (
        <Fragment>
            {/* <Typography variant='h5' component='h1' className={classes.formTitle}>Create Your Login</Typography> */}

            <form noValidate autoComplete='on' className={classes.form} id='form-register-createLogin'>
                <TextField 
                    id='register-createLogin-email' 
                    label='Email' 
                    value={email.value}
                    variant={inputVariant} 
                    autoComplete='email'
                    onChange={handleFormChange('createLogin', 'email')}
                    error={email.isDirty && !email.isValid}
                    helperText={email.error}
                    required/>

                <TextField 
                    id='register-createLogin-password' 
                    label='Password' 
                    data-testid='password'
                    type={showPassword ? 'text' : 'password'}
                    value={password.value}
                    onChange={handleFormChange('createLogin', 'password')}
                    variant={inputVariant} 
                    autoComplete='new-password' 
                    error={password.isDirty && !password.isValid}
                    helperText={password.error}
                    required
                    InputProps={{
                        endAdornment:(
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />

                <div id='user-agreement' className={classes.userAgreement}>
                    <Typography variant='h5' component='h1' align='center'>myProvidence User Agreement</Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={userAgreement}
                                onChange={handleFormChange('createLogin', 'userAgreement')}
                                name="userAgreement"
                                color="primary"
                            />
                        }
                        label={
                            <React.Fragment>
                                <span>I have read and agree to the </span>
                                <Link 
                                    target="_blank"
                                    rel="noopener"
                                    // href="https://myprovidence.healthtrioconnect.com/public-app/content/member/payorPage.page?xsesschk=&pptitle=myprovidence-user-agreement">
                                    // href="https://www.providencehealthplan.com/user-agreement">
                                    href={config.userAgreement}>
                                        user agreement</Link>
                            </React.Fragment>
                        }
                    />
                </div>
            </form>

        </Fragment>
    )
}

export default Step2