import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import RemoveIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles((theme) => ({
  policyWrapper: {
    margin: "20px !important",
  },
  requirementText: {
    color: "#5C6B7A",
    marginBottom: 10,
  },
  policy: {
    display: "flex",
    flexFlow: "row nowrap",
    fontSize: "0.9rem",
    lineHeight: 1.5,
    color: "#5C6B7A",
  },
  subPolicy: {
    paddingLeft: 15,
  },
  valid: {
    color: theme.palette.success.main,
  },
  invalid: {
    color: theme.palette.error.main,
  },
}));

const PasswordPolicy = ({ password, isValidPassword }) => {
  const classes = useStyles();
  const [passwordPolicy, setPasswordPolicy] = useState([
    {
      type: "length",
      message: "At least 8 characters in length",
      valid: false,
    },
    {
      type: "contain",
      message: "Contains at least 3 of the following 4 types of characters:",
      valid: false,
      item: [
        {
          type: "lowercase",
          message: "Lower case letters (a-z)",
          valid: false,
        },
        {
          type: "uppercase",
          message: "Upper case letters (A-Z)",
          valid: false,
        },
        {
          type: "numbers",
          message: "Numbers (i.e. 0-9)",
          valid: false,
        },
        {
          type: "speicalCharacter",
          message: "Special characters (e.g. !@#$%^&*)",
          valid: false,
        },
      ],
    },
  ]);

  useEffect(() => {
    const passwordEntered = password.length > 0;
    if (!passwordEntered) return false;
    //  ^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).+$

    const hasLowerCase = passwordEntered && /^(?=.*[a-z]).+$/.test(password);
    const hasUpperCase = passwordEntered && /^(?=.*[A-Z]).+$/.test(password);
    const hasNumber = passwordEntered && /^(?=.*\d).+$/.test(password);
    const hasSpecialCharacter =
      passwordEntered && /^(?=.*[-+_!@#$%^&*.,?]).+$/.test(password);

    const updatedPolicy = [
      {
        type: "length",
        message: "At least 8 characters in length",
        valid: passwordEntered && password.length > 7,
      },
      {
        type: "contain",
        message: "Contains at least 3 of the following 4 types of characters:",
        valid:
          +hasLowerCase + +hasUpperCase + +hasNumber + +hasSpecialCharacter >=
          3,
        item: [
          {
            type: "lowercase",
            message: "Lower case letters (a-z)",
            valid: hasLowerCase,
          },
          {
            type: "uppercase",
            message: "Upper case letters (A-Z)",
            valid: hasUpperCase,
          },
          {
            type: "numbers",
            message: "Numbers (i.e. 0-9)",
            valid: hasNumber,
          },
          {
            type: "specialCharacter",
            message: "Special characters (e.g. !@#$%^&*)",
            valid: hasSpecialCharacter,
          },
        ],
      },
    ];
    setPasswordPolicy(updatedPolicy);
  }, [password]);

  useEffect(() => {
    isValidPassword(passwordPolicy.reduce((a, b) => a && b.valid, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordPolicy]);

  return (
    <Container className={classes.policyWrapper}>
      <Typography className={classes.requirementText}>
        PASSWORD POLICY:
      </Typography>
      {passwordPolicy.map((policy) => (
        <div key={policy.type}>
          <Typography
            key={policy.type}
            className={clsx(
              classes.policy,
              password.length > 0 &&
                (policy.valid ? classes.valid : classes.invalid)
            )}
          >
            {password.length > 0 ? (
              policy.valid ? (
                <CheckIcon />
              ) : (
                <ClearIcon />
              )
            ) : (
              <RemoveIcon />
            )}
            {policy.message}
          </Typography>
          {policy?.item &&
            policy.item.map((subPolicy) => (
              <Typography
                key={subPolicy.type}
                className={clsx(
                  classes.policy,
                  classes.subPolicy,
                  password.length > 0 &&
                    (subPolicy.valid ? classes.valid : classes.invalid)
                )}
              >
                {password.length > 0 ? (
                  subPolicy.valid ? (
                    <CheckIcon />
                  ) : (
                    <ClearIcon />
                  )
                ) : (
                  <RemoveIcon />
                )}
                {subPolicy.message}
              </Typography>
            ))}
        </div>
      ))}
    </Container>
  );
};

export default PasswordPolicy;
